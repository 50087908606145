





































import { Component, Vue } from 'vue-property-decorator';
import HomeHero from '@/components/HomeHero.vue';
import BannerComponent from '@/components/BannerComponent.vue';
import { Banner } from '@/models/model';
import home from '@/store/home';

@Component({
  components: {
    HomeHero,
    BannerComponent
  },
  metaInfo() {
    return {
      title: `${this.$t('meta.homeTitle')} - ${this.$t('meta.skiRental')}`,
      titleTemplate: undefined,
      htmlAttrs: {
        lang: this.$root.$i18n.locale
      },
      meta: [
        {
          name: 'description',
          content: `${this.$t('meta.homeDescription')}`
        },
        {
          name: 'og:title',
          content: `${this.$t('meta.homeTitle')}`
        },
        {
          name: 'og:description',
          content: `${this.$t('meta.homeDescription')}`
        },
        {
          name: 'twitter:title',
          content: `${this.$t('meta.homeTitle')}`
        },
        {
          name: 'twitter:description',
          content: `${this.$t('meta.homeDescription')}`
        }
      ]
    }
  }
})
export default class Home extends Vue {

  goToElement(elementId: string): void {
    const element = document.querySelector('#' + elementId);
    const yOffset = -80;
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }

  get banner(): Banner | undefined {
    return home.banner;
  }
}
