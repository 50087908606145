






































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Ad extends Vue {
  showLightbox(): void {
    var modal = (window as any).bootstrap.Modal.getOrCreateInstance(this.$refs.lightboxModal);
    modal.show();
  }
}
