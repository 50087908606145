

























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Ad from './Ad.vue';
import home from '@/store/home';
import user from '@/store/user';
import { Picture, ProductType } from '@/models/model';
import Api from '@/api/api';

interface ProductTypeSummary {
  productType: number;
  imgs: Picture[];
  startingPrice: number;
  route: string;
}

@Component({ components: { Ad } })
export default class HomeHero extends Vue {

  productTypeSummaries: ProductTypeSummary[] = [];

  mounted(): void {
    this.productTypeSummaries = [];
    [1, 3, 4, 2, 6].forEach(i => this.productTypeSummaries.push({
      productType: i,
      imgs: this.getProductPictures(i).slice(0, 3),
      startingPrice: this.getStartingPrice(i),
      route: '/Rent/' + ProductType[i]
    }));
  }

  goTo(route: string): void {
    this.$router.push(route);
  }

  getProductPictures(productType: ProductType): Picture[] {
    return home.products.filter(p => p.type == productType).reduce((a, b) => [...a, ...b.pictures], ([] as Picture[]));
  }

  getStartingPrice(productType: ProductType): number {
    const weekPrices = home.products.filter(p => p.type == productType).map(p => p.rentPrices.find(r => r.days == 6)!.price);
    return Math.min(...weekPrices);
  }

  getPictureUrl(picture: Picture): string {
      return Api.apiUrl + 'v1/document/picture/' + picture.hash + '?fileName=' + picture.name;
  }

  get loading(): boolean {
    return home.loading;
  }

  get loggedIn(): boolean {
      return user.loggedIn;
  }

  get admin(): boolean {
      return !!user.currentUser && !!user.currentUser.roles && user.currentUser.roles.indexOf("Admin") !== -1;
  }
}
