



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Banner } from '@/models/model';

@Component
export default class BannerComponent extends Vue {

    @Prop()
    banner?: Banner;

    get langSuffix(): string {
        return this.$root.$i18n.locale === 'en' ? 'En' : '';
    }

}
